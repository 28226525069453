import React, { PropsWithChildren, useEffect } from 'react';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';

const WithAppInsights = ({ children }: PropsWithChildren) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const browserHistory = createBrowserHistory();
      const reactPlugin = new ReactPlugin();
      const appInsights = new ApplicationInsights({
        config: {
          connectionString: process.env.GATSBY_APP_INSIGHTS_KEY,
          enableAutoRouteTracking: true,
          extensions: [reactPlugin],
          extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory },
          },
        },
      });

      appInsights.loadAppInsights();
      appInsights.trackPageView({ name: window.location.pathname });
    }
  }, []);

  return children;
};

export default WithAppInsights;
