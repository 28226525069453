import React, { PropsWithChildren, useEffect } from 'react';
import { graphql, navigate, useStaticQuery } from 'gatsby';

import { parseRedirects, parseRedirectURL } from '../../utils/redirects';

type PageRedirect = {
  from: string;
  to: string;
};

interface RedirectProps extends PropsWithChildren {
  location: Location;
}

const Redirect = ({ children, location }: RedirectProps) => {
  const data = useStaticQuery(graphql`
    query allContentfulRedirects {
      allContentfulRedirect {
        nodes {
          from {
            ... on ContentfulPageArticle {
              slug
              pageName: title
              ...ParentPageArticle
            }
            ... on ContentfulPageGeneric {
              slug
              pageName
              ...ParentPageGeneric
            }
            ... on ContentfulPageWizard {
              slug
              pageName: wizardName
            }
          }
          fromSlug
          toUrl
          to {
            ... on ContentfulPageArticle {
              slug
              pageName: title
              ...ParentPageArticle
            }
            ... on ContentfulPageGeneric {
              slug
              pageName
              ...ParentPageGeneric
            }
            ... on ContentfulPageWizard {
              slug
              pageName: wizardName
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    const redirects = parseRedirects(data.allContentfulRedirect.nodes);

    redirects.forEach((redirect: PageRedirect) => {
      if (
        redirect.from === `${location.pathname}${location.search}` &&
        redirect.from !== redirect.to
      ) {
        const toURL = parseRedirectURL(redirect, location);

        navigate(toURL);
      }
    });
  }, [location, data]);

  return children;
};

export default Redirect;
