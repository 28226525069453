export enum TagTypes {
  SECTOR = 'sector',
  CHALLENGE = 'challenge',
  TOPIC = 'topic',
  PRODUCT = 'product',
  PRODUCT_CATEGORY = 'product-category',
  SEGMENT = 'segment',
  CATEGORY = 'category',
  CONTENT_TYPE = 'content-type',
}
